import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Tabs from '@sede-x/shell-core-ui/lib/es/Tabs'
import { Button, FormField, TextInput, Alert } from '@sede-x/shell-ds-react-framework'
import './paiement.scss'

const Paiement = () => {
  const [validation, setValidation] = useState(false)
  const [currentTab, setCurrentTab] = useState('carte-bancaire')
  const navigate = useNavigate()

  const tabs = { 'carte-bancaire': { label: 'Carte Bancaire', img: 'card-payment.png' }, 'orange-money': { label: 'Orange Money', img: 'orange-money.png' } }

  return (
    <div className="paiement">
      {!validation ? (
        <div>
          <div className="title">Mode de Paiement</div>
          <div>
            <div>
              <Tabs color="shell-dark-blue" className="paiement-tabs">
                {Object.keys(tabs).map((key) => (
                  <Tabs.Tab key={key} onClick={() => setCurrentTab(key)} active={currentTab === key}>
                    <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${tabs[key].img})` }} className="tab-header">
                      <span>{tabs[key].label}</span>
                    </div>
                  </Tabs.Tab>
                ))}
              </Tabs>
            </div>
            <div className="tab-content">
              {currentTab === 'carte-bancaire' && (
                <div>
                  <div className="two-fields">
                    <FormField label="Nom" mandatory size="medium">
                      <TextInput size="medium" type="text" placeholder="DIALLO" required />
                    </FormField>
                    <FormField label="Prenom" mandatory size="medium">
                      <TextInput size="medium" type="text" placeholder="Fatoumata" required />
                    </FormField>
                  </div>
                  <div className="card-number">
                    <div />
                    <FormField label="Numero de carte" mandatory size="medium">
                      <TextInput size="medium" type="text" placeholder="**** **** **** 5246" required />
                    </FormField>
                  </div>
                  <div className="two-fields">
                    <FormField label="Date d'Expiration" mandatory size="medium">
                      <TextInput size="medium" type="text" placeholder="07/2025" required />
                    </FormField>
                    <FormField label="CVC" mandatory size="medium">
                      <TextInput size="medium" type="text" placeholder="***" required />
                    </FormField>
                  </div>
                </div>
              )}
              {currentTab === 'orange-money' && (
                <div className="orange-money-content">
                  <FormField label="Numero de Telephone" mandatory size="medium">
                    <TextInput size="medium" type="text" placeholder="76 14 86 14" required />
                  </FormField>
                  <FormField label="Code de paiement" description="Pour obtenir le numero, faite le #144#152" mandatory size="medium">
                    <TextInput size="medium" type="text" placeholder="1452697745" required />
                  </FormField>
                </div>
              )}

              <div className="footer">
                <Button onClick={() => setValidation(true)}> Suivant</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="validation">
          <Alert sentiment={'positive'}>Votre demande a bien été prise en compte</Alert>
          <div className="footer">
            <Button
              onClick={() => {
                navigate({ pathname: '/mon-espace-public' })
                setValidation(false)
              }}
            >
              Valider
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Paiement
