import React from 'react'
import { Tree, Icons } from '@sede-x/shell-ds-react-framework'
import './liens.scss'

const Liens = ({ person }) => {
  const defaultTreeData = [
    {
      title: `${person.prenom} ${person.nom}`,
      isExpanded: true,
      children: [
        {
          title: person.Pere,
          isExpanded: true,
          icon: (
            <div className="tree-item">
              <Icons.PersonSolid height={24} width={24} />
              Pere:
            </div>
          ),
        },
        {
          title: person.Mere,
          isExpanded: true,
          icon: (
            <div className="tree-item">
              <Icons.PersonSolid height={24} width={24} />
              Mere:
            </div>
          ),
        },
      ],
    },
  ]
  return (
    <div className="liens">
      <Tree treeData={defaultTreeData} />{' '}
    </div>
  )
}

export default Liens
