import React, { useState } from 'react'
import { Modal } from '@sede-x/shell-core-ui'
import './diplomes.scss'

const Diplomes = ({ person }) => {
  const [open, setOpen] = useState(false)
  const [diplome, setDiplome] = useState(null)

  const diplomes = person.diplomes
  return (
    <>
      <div className="diplomes-list">
        {diplomes.map((d) => (
          <div>
            <p>{d.title}</p>
            <div
              style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${d.img})` }}
              className="diplome-card"
              onClick={() => {
                setDiplome(d)
                setOpen(true)
              }}
            />
          </div>
        ))}
      </div>

      <Modal
        size="medium"
        closable
        visible={open}
        onClose={() => {
          setOpen(false)
          setDiplome(null)
        }}
        title={diplome?.title}
        bodyPadding={false}
      >
        {/* <div className="diplome-view" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/${diplome?.img})` }} /> */}
        <iframe src={`${process.env.PUBLIC_URL}/${diplome?.pdf}`} width={`${window.innerWidth / 1.5}px`} height={`${window.innerHeight / 1.4}px`} />
      </Modal>
    </>
  )
}

export default Diplomes
