import React from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import Icon from '@sede-x/shell-core-ui/lib/es/Icon'
import './servicePublic.scss'

const ServicePublic = () => {
  const configList = [
    { name: "Demande d'Extrait d'Acte de Naissance", description: "Service en ligne officiel de demande d'extrait d'acte de naissance " },
    { name: 'Demande de Certificat de Nationalité', description: 'Service en ligne officiel de demande de certificat de nationalité ' },
    { name: "Demande d'Extrait de Casier Judiciaire", description: "Service en ligne officiel de demande d'extrait de casier judiciaire " },
    { name: 'Candidature en ligne à un engagement de service civique', description: 'Service en ligne ' },
    { name: 'Demande de Certificat de Residence', description: 'Service en ligne officiel de demande de certificat de residence ' },
    { name: "Signaler son Changement d'Adresse en ligne", description: "Service en ligne officiel pour signaler son changement d'adresse en ligne " },
  ]
  const navigate = useNavigate()

  return (
    <div className="service-public-container">
      <div>
        <div className="title">Mon Espace Service Public</div>
        <div className="service-public">
          {configList.map(({ name, description }, i) => (
            <div onClick={() => (i === 0 ? navigate({ pathname: '/demande-acte-de-naissance' }) : {})} className={cx({ 'is-disabled': i !== 0 })}>
              <p>{name}</p>
              <p>{description}</p>
              <Icon icon="arrow-point-right" primary={'shell-dark-blue'} xsmall />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServicePublic
