import React from 'react'
import { Timeline } from '@sede-x/shell-ds-react-framework'
import './activities.scss'

const Activities = ({ person }) => {
  const activities = person.activities
  return <Timeline size="small" className="activity-steps" alignment={'right'} direction="vertical" steps={activities.map((a) => ({ ...a, variant: 'filled' }))} />
}

export default Activities
