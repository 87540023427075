import React, { useState } from 'react'
import moment from 'moment'
import './dashboard.scss'
import Table from '@sede-x/shell-core-ui/lib/es/Table'
import Icon from '@sede-x/shell-core-ui/lib/es/Icon'
import { getAge } from '../../helper'
import { Button } from '@sede-x/shell-ds-react-framework'
import Profil from '../Profil'
import SideBar from '../../components/SideBar'

const Dashboard = ({ list, setList, person, setPerson }) => {
  const columns = [
    {
      title: 'Numero Nina',
      ellipsis: true,
      dataIndex: 'nina',
      width: '10%',
      render: (data, record) => {
        return (
          <>
            <span className="nina-number">{data}</span>
            {record.transactionsAmo.length > 0 && (
              <div className="card-bottom-info">
                <Icon icon="warning" primary={'red'} xsmall />
                <span>{record.transactionsAmo.length} anomalies</span>
              </div>
            )}
          </>
        )
      },
    },

    {
      title: 'Nom/Prenom/Age',
      dataIndex: 'nom',
      width: '20%',
      render: (_, record) => {
        const { nom, prenom, dateDeNaissance } = record
        return (
          <div className="first-last-name-container">
            <Icon icon="user" xsmall className="person-icon" />
            <div className="first-last-name">
              <span>{`${prenom} ${nom.toUpperCase()}`}</span>
              <span>{getAge(dateDeNaissance)} ans</span>
            </div>
          </div>
        )
      },
    },

    {
      title: 'Telephone',
      dataIndex: 'telephone',
      ellipsis: true,
      width: '10%',
      render: (data) => {
        return <span className="telephone nina-number">{data}</span>
      },
    },

    {
      title: 'Profession',
      dataIndex: 'Profession',
      width: '15%',
      render: (data) => {
        return <span>{data}</span>
      },
    },

    {
      title: 'Date Enrollement',
      dataIndex: 'dateEnrollement',
      width: '15%',
      render: (data) => {
        return (
          <div className="first-last-name date-enrolement">
            <span>{moment(data).format('DD/MM/YYYY')}</span>
            <span>{moment(data).format('HH:mm')}</span>
          </div>
        )
      },
    },

    {
      title: 'Adresse',
      dataIndex: 'adresse',
      width: '15%',
    },

    {
      title: 'Region',
      dataIndex: 'region',
      width: '10%',
      render: (_, record) => {
        const { region, ville } = record
        return (
          <div className="first-last-name">
            <span>{region}</span>
            <span>{ville}</span>
          </div>
        )
      },
    },
  ]
  const mobileColumns = [
    {
      title: 'Nom/Prenom/Age',
      dataIndex: 'nom',
      width: '60%',
      render: (_, record) => {
        const { nom, prenom, dateDeNaissance, nina } = record
        return (
          <>
            <div className="mobile-nom-nina">
              <div className="first-last-name-container">
                <Icon icon="user" xsmall className="person-icon" />
                <div className="first-last-name">
                  <span>{`${prenom} ${nom.toUpperCase()}`}</span>
                  <span>{getAge(dateDeNaissance)} ans</span>
                </div>
              </div>
              <div className="nina-number">Nina: {nina}</div>
            </div>
          </>
        )
      },
    },

    {
      title: 'Adresse',
      dataIndex: 'region',
      width: '40%',
      render: (_, record) => {
        const { region, ville, adresse } = record
        return (
          <div className="first-last-name">
            <span>{adresse}</span>
            <span>{region}</span>
            <span>{ville}</span>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <SideBar list={list} setList={setList} />
      <div className="dashboard-container">
        <div className="title">
          {person && <Button size="xsmall" sentiment="default" variant="transparent" onClick={() => setPerson(null)} icon={<Icon icon="arrow-point-left" xsmall />} />}
          <span>Population</span>
        </div>
        {!person ? (
          <>
            <div className="list desktop-view">
              <Table
                columns={columns}
                data={list}
                rowType="cards"
                autosize
                rowHeight={120}
                onRow={(record, index) => ({
                  className: record.transactionsAmo.length > 0 ? 'has-alert' : '',
                  onClick: () => setPerson(record),
                })}
              />
            </div>
            <div className="list mobile-view">
              <Table
                columns={mobileColumns}
                data={list}
                rowType="cards"
                autosize
                rowHeight={150}
                onRow={(record) => ({
                  className: record.transactionsAmo.length > 0 ? 'has-alert' : '',
                  onClick: () => setPerson(record),
                })}
              />
            </div>
          </>
        ) : (
          <div className="profil-ctn">
            <Profil person={person} />
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard
