import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import { getAge } from '../../helper'

const AmoElt = ({ index, keyId, person, transaction }) => {
  return (
    <div className={cx('amo-card', { odd: index % 2 === 1 })}>
      <div>
        <span>Transaction Id</span>
        <span>{transaction.transactionId}</span>
      </div>

      <div data-key={keyId}>
        <span>Date</span>
        <span>{moment(transaction.date).format('DD/MM/YYYY')}</span>
        <span>{moment(transaction.date).format('HH:mm')}</span>
      </div>

      <div data-key={keyId}>
        <span>Lieu</span>
        <span>{transaction.pharmacie}</span>
        <span>{transaction.ville}</span>
      </div>

      <div data-key={keyId}>
        <span>Medicament</span>
        <span>{transaction.medicament}</span>
        <span>{`${transaction.description} ${keyId === 'critere-age' ? `, Patient Age: ${getAge(person.dateDeNaissance)} ans` : ''}`}</span>
      </div>

      <div>
        <span>Montant</span>
        <span>{transaction.prix}</span>
      </div>
    </div>
  )
}

export default AmoElt
