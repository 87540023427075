import React from 'react'
import ReactDOM from 'react-dom/client'
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework'
import { BrowserRouter } from 'react-router-dom'
import '@sede-x/shell-core-ui/lib/index.css'
import './index.scss'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ShellThemeProvider theme="light">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ShellThemeProvider>,
)
