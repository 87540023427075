import React from 'react'
import Accordion from '@sede-x/shell-core-ui/lib/es/Accordion'
import './transactionsAmo.scss'
import AmoElt from './AmoElt'

const TransactionsAmo = ({ person }) => {
  const transactionsAmo = person.transactionsAmo

  const reglesListe = [
    { key: 'critere-emplacement', description: 'Critère de Localisation non respecté' },
    { key: 'critere-age', description: "Critère d'Age non respecté" },
  ]
  return (
    <div className="amo-transaction-container">
      <Accordion defaultActiveKey={['critere-emplacement', 'critere-age']}>
        {reglesListe.map(({ key, description }) => (
          <Accordion.Panel className="amo-card-lists-container" key={key} header={description}>
            <div>
              {transactionsAmo
                .filter((t) => t.flag === key)
                .map((t, i) => (
                  <AmoElt index={i} person={person} transaction={t} keyId={key} />
                ))}
            </div>
          </Accordion.Panel>
        ))}
      </Accordion>
    </div>
  )
}

export default TransactionsAmo
