import React, { useState } from 'react'
import Tabs from '@sede-x/shell-core-ui/lib/es/Tabs'
import './profil.scss'
import { getAge } from '../../helper'
import Activities from '../../components/Activities'
import Diplomes from '../../components/Diplomes'
import TransactionsAmo from '../../components/TransactionsAmo'
import Liens from '../../components/Liens'

const Profil = ({ person }) => {
  const tabs = {
    activites: { label: 'Activités', component: Activities },
    amo: { label: 'Opérations Amo', component: TransactionsAmo },
    diplome: { label: 'Documents', component: Diplomes },
    liens: { label: 'Famille', component: Liens },
  }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0])
  const TabContent = tabs[currentTab || Object.keys(tabs)[0]].component

  return (
    <div className="profil-container">
      <div className="info">
        <img src={`${process.env.PUBLIC_URL}/${person.photo}`} className="profil-img" />
        <div className="first-last-name">
          <span>{`${person.prenom} ${person.nom.toUpperCase()}`}</span>
          <span>{getAge(person.dateDeNaissance)} ans</span>
          <span>{person.adresse} </span>
        </div>
      </div>

      <div className="content">
        <div className="right">
          <Tabs color="shell-dark-blue" className="profil-tabs">
            {Object.keys(tabs).map((key) => (
              <Tabs.Tab key={key} onClick={() => setCurrentTab(key)} active={currentTab === key}>
                {tabs[key].label}
              </Tabs.Tab>
            ))}
          </Tabs>
          <div className="tabs-content">
            <TabContent person={person} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profil
