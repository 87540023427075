import React from 'react'
import { useNavigate } from 'react-router-dom'
import './demandeActeDeNaissance.scss'
import { Button, FormField, TextInput } from '@sede-x/shell-ds-react-framework'

const DemandeActeDeNaissance = () => {
  const navigate = useNavigate()

  return (
    <div className="demande-acte-de-naissance-container">
      <div>
        <div className="title">Demande d'Extrait d'Acte de Naissance</div>
        <div>
          <FormField label="Nom" mandatory size="medium">
            <TextInput size="medium" type="text" placeholder="Nom de Naissance" required />
          </FormField>
          <FormField label="Prenom" mandatory size="medium">
            <TextInput size="medium" type="text" placeholder="Prenom de Naissance" required />
          </FormField>
          <FormField label="Date de Naissance" description="format JJ/MM/AAAA" mandatory size="medium">
            <TextInput size="medium" type="text" placeholder="Date de Naissance" required />
          </FormField>
          <FormField label="Email" description="Email sur lequel vous souhaitez recevoir le document" mandatory size="medium">
            <TextInput size="medium" type="text" placeholder="Fatou.diallo@gmail.com" required />
          </FormField>
          <div className="footer">
            <Button onClick={() => navigate({ pathname: '/paiement' })}> Suivant</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemandeActeDeNaissance
