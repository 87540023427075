import React from 'react'
import { NavBar, Icons } from '@sede-x/shell-ds-react-framework'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/drapeau.png'
import './header.scss'

const Header = () => {
  const navigate = useNavigate()

  const menuItems = [
    {
      label: 'Dashboard',
      itemIcon: <Icons.ContentSolid />,
      path: '/dashboard',
      key: '/dashboard',
      onClick: ({ key }) => navigate(key),
    },
    {
      label: 'Mon Espace Public',
      itemIcon: <Icons.Grid height={24} width={24} />,
      path: '/mon-espace-public',
      key: '/mon-espace-public',
      onClick: ({ key }) => navigate(key),
    },
  ]

  return (
    <div className="header-container">
      <NavBar
        pectenArea={<img src={Logo} className="logo" onClick={() => navigate('/dashboard')} />}
        items={menuItems}
        avatarProps={{
          label: 'Fatoumata DIALLO',
          additionalText: 'Nina: 96542358654',
        }}
        // elevation={false}
        selectedPath={location.pathname}
      />
    </div>
  )
}

export default Header
