import React, { useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Header from './components/Header'
import Dashboard from './Pages/Dashboard'
import data from './data.json'
import './App.scss'
import ServicePublic from './Pages/ServicePublic'
import DemandeActeDeNaissance from './Pages/DemandeActeDeNaissance'
import Paiement from './Pages/Paiement'

const App = () => {
  const [list, setList] = useState(data)
  const [person, setPerson] = useState(null)

  return (
    <div className="app">
      <Header />
      <div className="page-content">
        <Routes>
          <Route path={'/dashboard'} element={<Dashboard setList={setList} list={list} person={person} setPerson={setPerson} />} />
          <Route path={'/mon-espace-public'} element={<ServicePublic />} />
          <Route path={'/demande-acte-de-naissance'} element={<DemandeActeDeNaissance />} />
          <Route path={'/paiement'} element={<Paiement />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
