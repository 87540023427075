import React, { useState } from 'react'
import cx from 'classnames'
import { Menu, MenuItem, FormField, Option, Select, Icons } from '@sede-x/shell-ds-react-framework'
import data from '../../data.json'
import './sidebar.scss'

const menuItems = [
  { key: 'amo-alert', label: 'Dossiers en Alerte', count: 123 },
  { key: 'contraventions', label: 'Contraventions', count: 25 },
]

const filterList = [
  { key: 'nina', label: 'Numero Nina' },
  { key: 'region', label: 'Region' },
  { key: 'ville', label: 'Ville' },
  { key: 'nom', label: 'Nom' },
  { key: 'prenom', label: 'Prenom' },
]

const SideBar = ({ list, setList }) => {
  const [filters, setFilers] = useState({})
  const [openOnMobile, setOpenOnMobile] = useState(false)
  const [menuSelected, setMenuSelected] = useState(menuItems[0].key)

  const filterOptions = filterList.reduce((acc, cur) => ({ ...acc, [cur.key]: data.map((l) => l[cur.key]) }), {})

  const onFilterChange = (key, value) => {
    const newFilters = { ...filters, [key]: value }
    setFilers(newFilters)
    if (Object.values(newFilters).every((e) => e.length === 0)) return setList(data)
    const newList = data.filter((l) => Object.keys(newFilters).some((key) => newFilters[key].indexOf(l[key]) !== -1))
    setList(newList)
  }

  return (
    <div className={cx('sidebar-container', { 'is-open-on-mobile': openOnMobile })}>
      <Icons.Filter className="filter-icon" onClick={() => setOpenOnMobile(true)} />
      <Icons.Cross className="close-icon" onClick={() => setOpenOnMobile(false)} />

      <div className={cx('sidebar-content')}>
        <div className="section">
          <div className="title">Menu</div>
          <div className="overview">
            <Menu
              selectedKeys={[menuSelected]}
              onSelect={({ key }) => {
                setMenuSelected(key)
              }}
            >
              {menuItems.map(({ label, count, key }) => (
                <MenuItem key={key}>
                  <div className={cx('menu-item-custom', { selected: menuSelected === key })}>
                    <span>{label}</span>
                    {count > 0 && <span>{count}</span>}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>

        <div className="section">
          <div className="title">Filtres</div>
          <div className="filters">
            {filterList.map(({ key, label }) => (
              <FormField label={label} size="medium">
                <Select mode="multiple" size="medium" onChange={(val) => onFilterChange(key, val)} allowClear>
                  {filterOptions[key]
                    .filter((e, i, arr) => arr.indexOf(e) === i)
                    .sort((a, b) => a.localeCompare(b))
                    .map((label) => (
                      <Option key={label}>{label.toUpperCase()}</Option>
                    ))}
                </Select>
              </FormField>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
